<template>
  <div class="experience">
    <div class="bg"><img :src="bg" alt=""></div>
    <div class="main">
      <div class="str">
        <h2>开始合作</h2><br>
        <p>让医键通成为您企业的助手。接下来我们一起讨论下一步该做什么，完全按照您的需求定制。一切就是这么简单！</p><br>
        <p>由于这类软件在市面上品质参差不齐，我们把医键通的功能定义为由模块组成的辅助软件。我们会告诉你如何找到你所需要的模块，并且去掉你不想要的，获得您最想要的的信息。</p>
      </div>
      <div class="putit">
        <h2>获得软件体验</h2>
        <div class="inputstr" 
              v-for="(item,index) in putit" 
              :key="index"
              >
              <input type="text" :placeholder="item.name" v-model="item.input2">
        </div>
        <div class="inputput" @click="gogogo">提交申请</div>
      </div>
    </div>
    <foots/>
  </div>
</template>

<script>
import axios from 'axios'
import foots from '@/components/mobile/foot'
export default {
    components:{
          foots,
      },
    name:'experience',
    data(){
      return{
        bg:require('@/assets/img/mobileImg/experienceImg/bg.png'),
        putit:[
          {name:'企业名称',input2:'',ename:'企业名称：'},
          {name:'如何称呼您',input2:'',ename:'姓名：'},
          {name:'手机号码',input2:'',ename:'手机：'},
          {name:'需要咨询的功能',input2:'',ename:'问题：'},
        ]
      }
    },
    methods:{
      gogogo(){
        let j=0
        for(var i=0;i<this.putit.length;i++){
          if(this.putit[i].input2 == '' ){
            j++
          }
        }
        let fromdata = new FormData()
        let str = ''
        this.putit.forEach((v,i)=>{
          str += v.ename  + ' ' + v.input2 + ' '
        })
        fromdata.append('content',str)
        if(j==0){
          axios({
              method: 'post',
              url: 'https://say.ektlang.com/ektlang/sendemail',
              data: fromdata
            }).then(res => {
              if(res.data.success){
                alert('发送成功')
                this.putit[0].input2 = ''
                this.putit[1].input2 = ''
                this.putit[3].input2 = ''
                this.putit[2].input2 = ''
              }else{
                alert(res.data.msg)
              }
            });
        }else{
          alert('请输入内容')
        }

      }
    }
    
}
</script>

<style lang="less" scoped>
input::-webkit-input-placeholder {
color:#697598; /*WebKit browsers*/
}
input::-moz-placeholder {
color:#697598 ; /*Mozilla Firefox 4 to 18 */
}
input::-moz-placeholder {
color:#697598 ; /*Mozilla Firefox 19+ */
}
input::-ms-input-placeholder {
color:#697598 ; /*Internet Explorer 10+*/
}

input::-webkit-input-value {
color:black; /*WebKit browsers*/
}
input::-moz-value {
color:black ; /*Mozilla Firefox 4 to 18 */
}
input::-moz-value {
color:black ; /*Mozilla Firefox 19+ */
}
input::-ms-input-value {
color:black ; /*Internet Explorer 10+*/
}
.experience{
    width: 23.4375rem;
    height: 50.75rem;
    .bg{
      width: 23.4375rem;
      height: 50.75rem;
      position: relative;
      z-index: -5;
      img{
        width: 100%;
        position: relative;
        z-index: -5;
      }
    }
    .main{
      margin: -46.4rem 0 0 0;
      .str{
        width: 19.6875rem;
        margin: 0 auto;
        color: #FFFFFF;
        h2{
          text-align: center;
          font-size: 1.75rem;
          font-weight: bold;
        }
        p{
          font-size: 0.875rem;
          font-weight: 400;
          text-indent: 1em;
        }
      }
      .putit{
        width: 21.5625rem;
        height: 31rem;
        background: #FFFFFF;
        box-shadow: 0rem 0.9375rem 2.6875rem rgba(0, 0, 0, 0.05);
        border-radius: 0.375rem;
        margin: 2.6875rem auto 0;
        h2{
          float: left;
          font-size: 1.5rem;
          font-weight: 400;
          color: #31384E;
          margin: 2.1875rem 0 2.4375rem 6.0625rem;
        }
        .inputstr{
          float: left;
          width: 18.8125rem;
          height: 3.125rem;
          background: #FBFBFB;
          border-radius: 0.375rem;
          margin: 0 0 1.6875rem 1.375rem;
          input{
            width: 17.5rem;
            height: 1.0625rem;
            font-size: 0.8125rem;
            font-weight: 400;
            color:  black;
            opacity: 0.9;
            margin: 1rem 0 0 0.5625rem;
            background-color:  #FBFBFB;
          }
        }
        .inputput{
          float: left;
          width: 18.8125rem;
          height: 3.0625rem;
          background: linear-gradient(132deg, #2655EE 0%, #34B3FF 100%);
          box-shadow: 0rem 0.125rem 1rem rgba(32, 123, 250, 0.5);
          border-radius: 0.375rem;
          line-height: 3.0625rem;
          text-align: center;
          font-size: 1rem;
          font-weight: bold;
          color: #FFFFFF;
          margin: 0 0 0 1.375rem;
        }
      }
    }
  
  /deep/ .foot{
        background-color: rgba(0, 0, 0, 0);
        p{
            color: #fff;
            a{
                color: #fff;
            }
        }
    }
}
  
</style>
